import {
  AppBar,
  Box,
  Button,
  Grid,
  Toolbar,
  Typography
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useEffect, useState } from 'react';
import { getFormSchemas } from '../../api/FormDataService';
import CardFormFromUniform from '../../components/FormFromSchema';
import { Messages } from '../../i18n';
import { AppFormData, RJSFSchema } from '../../models';
import appFormStyles from '../../styles/appFormStyles';
import { parseCognitoData } from '../../utils/cognitoData';
interface AppFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleLogout: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
}

export default function AppForm({
  user,
  handleLogout
}: AppFormProps): JSX.Element {
  const [formSchemas, setSchema] = useState<RJSFSchema[]>();

  const parsedSellerInfo = parseCognitoData(user);
  const formData: AppFormData = {
    seller: user.username,
    sellerInfo: parsedSellerInfo,
    formRequestId: ''
  };

  const classes = appFormStyles();

  useEffect(() => {
    if (!formSchemas) setSchema(getFormSchemas());
  }, [formSchemas]);

  if (!formSchemas) {
    return (
      <>
        <Box sx={{ flexGrow: 1 }} data-testid="app-condition">
          <AppBar position="static" className={classes.header}>
            <Toolbar>
              <Typography style={{ flex: 1, fontSize: '1.5rem' }}>
                {Messages.APP_TITLE}
              </Typography>
              <Button color="inherit" onClick={handleLogout}>
                <ExitToAppIcon />
              </Button>
            </Toolbar>
          </AppBar>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }} data-testid="app-condition">
        <AppBar position="static" className={classes.header}>
          <Toolbar>
            <img
              className={classes.appBarLogo}
              src="/assets/nutresa_logo_white2.png"
              alt="logo"
            />
            <img
              className={classes.appBarLogo}
              src="/assets/cream_helado_logo.png"
              alt="logo"
              style={{ width: '45px' }}
            />
            <Typography style={{ flex: 1, fontSize: '100%' }}>
              {Messages.APP_TITLE}
            </Typography>
            <Button
              role="button"
              aria-label="btnLogout"
              name="btnLogout"
              color="inherit"
              onClick={handleLogout}
            >
              <ExitToAppIcon style={{ fontSize: '2em' }} />
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          <Grid className={classes.CardForm} container>
            <Grid item xs={12} md={12}>
              <CardFormFromUniform
                title={Messages.FORM_CARD_TITLE}
                formData={formData}
                schemas={formSchemas}
              />
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
}
