import {
  AppBar,
  Box,
  Button,
  Grid,
  Toolbar,
  Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LoadError, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { Messages } from '../../i18n';
import { S3LoadErrorMap } from '../../models';
import appFormStyles from '../../styles/appFormStyles';
import S3LoadErrorMapData from '../data/s3_error.json';
interface AppFormProps {
  handleLogout: any;
  user: any;
}


export function renderError(error: LoadError, setS3Error: any) {
  setS3Error(true);
  const error_value = getS3LoadErrorMap()[error.name!];
  const message = error_value == undefined ? 'No se pudo cargar el documento' : error_value;
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: '#e53e3e',
          borderRadius: '0.25rem',
          color: '#fff',
          padding: '0.5rem',
        }}
      >
        {message}
      </div>
    </div>
  );
}

export function getS3LoadErrorMap(): S3LoadErrorMap {
  return S3LoadErrorMapData as S3LoadErrorMap;
}

export async function updateUserAttributes() {
  const user = await Auth.currentAuthenticatedUser();
  const current_date = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString();
  await Auth.updateUserAttributes(user, {
    "custom:Terminos_Condiciones": 'true'
  });
  await Auth.updateUserAttributes(user, {
    "custom:Fecha_Terminos": current_date
  });
  setTimeout(() => { console.log('esperando') }, 15000)
  window.location.reload()
}

export default function AppCondition({
  handleLogout
}: AppFormProps): JSX.Element {

  const classes = appFormStyles();

  const [open, setOpen] = useState(false);
  const [s3Error, setS3Error] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => { setOpen(false); };

  const pdf = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BUCKET_S3 || ''
    : process.env.REACT_APP_BUCKET_S3_PRO || ''

  console.log(pdf)

  return (
    <>
      <Box sx={{ flexGrow: 1 }} data-testid="app-condition">
        <AppBar position="static" className={classes.header}>
          <Toolbar>
            <img
              className={classes.appBarLogo}
              src="/assets/nutresa_logo_white2.png"
              alt="logo"
            />
            <img
              className={classes.appBarLogo}
              src="/assets/cream_helado_logo.png"
              alt="logo"
              style={{ width: '45px' }}
            />
            <Typography style={{ flex: 1, fontSize: '1.5rem' }}>
              {Messages.APP_TITLE}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid className={classes.CardForm} container >
          <Grid item xs={12} md={12}>
            <div className='pdf-container'>
              <form action="" method="post">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.3.200/build/pdf.worker.min.js">
                  <Viewer renderError={(e) => renderError(e, setS3Error)} fileUrl={pdf} />
                </Worker>
                {s3Error &&
                  <Button
                    className={classes.submittingButtonTerms}
                    size="large"
                    variant="contained"
                    onClick={handleLogout}>
                    Volver al inicio de sesión
                  </Button>
                }
                {!s3Error &&
                  <>
                    <Button
                      data-testid="botón"
                      className={classes.submittingButtonTerms}
                      size="large"
                      variant="contained"
                      onClick={handleClickOpen}>
                      He leido los términos y condiciones
                    </Button>
                    <div id='ocultar'>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">{"Estás de acuerdo con los términos y condiciones?"}</DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Evaluación de punto de venta
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleLogout} color="primary">
                            desacuerdo
                          </Button>
                          <Button onClick={updateUserAttributes} color="primary" autoFocus>
                            De acuerdo
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </>
                }
              </form>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}


